/* Mehr Nastaliq Web Font v2 */
@font-face {
  font-family: "Mehr";
  src: url("https://cdn.jsdelivr.net/npm/mehr-nastaliq/fonts/mehr.woff2") format("woff2");
}

/* CSS Aplly */
.post-title {
  font-family: "Mehr";
}

body {
  font-family: "Mehr";
}

.post-body {
  font-family: "Mehr";
}
